// RazorpayCheckout.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserController } from "../../Redux/controllers/UserController";

const RazorpayCheckout = ({ selectedAddress }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        console.log("Razorpay SDK loaded successfully");
        resolve(true);
      };
      script.onerror = () => {
        console.log("Razorpay SDK failed to load");
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    try {
      setIsProcessing(true);

      // 1. Load Razorpay SDK
      const isLoaded = await loadRazorpayScript();
      if (!isLoaded) {
        throw new Error("Razorpay SDK failed to load");
      }

      // 2. Create Order
      const orderResponse = await UserController.createUserOrder({
        shippingAddress: selectedAddress,
        paymentMethod: "razorpay",
      });

      if (
        !orderResponse?.data?.success ||
        !orderResponse?.data?.data?.razorpayOrderId
      ) {
        throw new Error("Order creation failed");
      }

      const { razorpayOrderId, remainingAmount } = orderResponse?.data?.data;

      // 3. Initialize Razorpay Options
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: Math.round(remainingAmount * 100),
        currency: "INR",
        name: "JBS Store",
        description: "Order Payment",
        order_id: razorpayOrderId,
        handler: async function (response) {
          try {
            await UserController.verifyPayment({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });
            navigate("/dashboard", { replace: true });
          } catch (error) {
            console.error("Payment verification error:", error);
          }
        },
        prefill: {
          name: selectedAddress?.name || "",
          contact: selectedAddress?.phone || "",
          email: selectedAddress?.email || "",
        },
        modal: {
          confirm_close: true,
          ondismiss: function (reason) {
            setIsProcessing(false);
            if (reason === undefined) {
              console.log("Payment cancelled by user");
            } else if (reason === "timeout") {
              console.log("Payment timeout");
            } else {
              console.log("Payment failed:", reason);
            }
          },
        },
        retry: {
          enabled: false,
        },
        timeout: 300,
        theme: {
          color: "#111",
        },
      };

      // 4. Create Razorpay Instance and Open Modal
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Payment initialization error:", error);
      alert(error.message || "Failed to initialize payment");
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center p-6">
      <h2 className="text-2xl font-semibold mb-6">Complete Your Payment</h2>

      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-md">
        <div className="mb-4">
          <h3 className="font-medium">Delivery Address:</h3>
          <p className="text-gray-600">
            {selectedAddress?.name}
            <br />
            {selectedAddress?.street}
            <br />
            {selectedAddress?.city}, {selectedAddress?.state}
            <br />
            {selectedAddress?.pincode}
          </p>
        </div>

        <button
          onClick={handlePayment}
          disabled={isProcessing}
          className={`
            w-full py-3 px-4 rounded-lg
            ${
              isProcessing
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }
            text-white font-medium transition-colors
          `}
        >
          {isProcessing ? "Processing..." : "Pay Now"}
        </button>
      </div>
    </div>
  );
};

export default RazorpayCheckout;
