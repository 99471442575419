import parse from "html-react-parser";
import toast from "react-hot-toast";

const sToast = (msg) => {
  toast.success(msg, {
    duration: 2000,
    position: "top-center",
  });
};
const eToast = (msg) => {
  toast.error(msg, {
    duration: 2000,
    position: "top-center",
  });
};
const MoneyFormatter = (value) => {
  if (value == null) return "N/A";

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
};
const HTMLParser = (htmlString) => {
  return parse(htmlString);
};

const Utility = {
  sToast,
  eToast,
  HTMLParser,
  MoneyFormatter,
};

export default Utility;
