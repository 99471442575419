import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CartController } from "../controllers/CartController";
import { logOut } from "./userSlice";
import Utility from "../../Utils/Utility";

// Fetch Cart Items
export const fetchCartAsync = createAsyncThunk(
  "cart/fetchCart",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CartController.fetchCart();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add Item to Cart
export const addToCartAsync = createAsyncThunk(
  "cart/addToCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CartController.addToCart(data);
      Utility.sToast("Item Added to Cart");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add Item to Cart
export const updateCartAsync = createAsyncThunk(
  "cart/updateCart",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CartController.updateCart(data);
      Utility.sToast("Cart Updated");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Remove a Single Cart Item (Optimized)
export const removeCartItemAsync = createAsyncThunk(
  "cart/removeCartItem",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CartController.removeCartItem(data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Clear Entire Cart
export const clearCartAsync = createAsyncThunk(
  "cart/clearCart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await CartController.clearCart();
      return [];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Cart Slice
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: null,
    isCartLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Cart
      .addCase(fetchCartAsync.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(fetchCartAsync.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.cart = action.payload.cart;
      })
      .addCase(fetchCartAsync.rejected, (state, action) => {
        state.isCartLoading = false;
        state.error = action.payload.cart;
      })

      // Add Cart Item
      .addCase(addToCartAsync.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(addToCartAsync.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.cart = action.payload.cart;
      })
      .addCase(addToCartAsync.rejected, (state, action) => {
        state.isCartLoading = false;
        state.error = action.payload.cart;
      })
      // Update Cart Item
      .addCase(updateCartAsync.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(updateCartAsync.fulfilled, (state, action) => {
        state.isCartLoading = false;
        state.cart = action.payload.cart;
      })
      .addCase(updateCartAsync.rejected, (state, action) => {
        state.isCartLoading = false;
        state.error = action.payload.cart;
      })

      // Remove Cart Item (Only delete that item)
      .addCase(removeCartItemAsync.pending, (state, action) => {
        state.isCartLoading = true;
      })
      .addCase(removeCartItemAsync.fulfilled, (state, action) => {
        state.isCartLoading = null;
        state.cart = action.payload.cart;
      })
      .addCase(removeCartItemAsync.rejected, (state, action) => {
        state.isCartLoading = null;
        state.error = action.payload;
      })

      // Clear Entire Cart
      .addCase(clearCartAsync.pending, (state) => {
        state.isCartLoading = true;
      })
      .addCase(clearCartAsync.fulfilled, (state) => {
        state.isCartLoading = false;
        state.cart = [];
      })
      .addCase(clearCartAsync.rejected, (state, action) => {
        state.isCartLoading = false;
        state.error = action.payload;
      })
      .addCase(logOut, (state) => {
        state.cart = [];
        state.isCartLoading = false;
        state.error = null;
      });
  },
});

export default cartSlice.reducer;
