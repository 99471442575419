import React, { useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import "./ProductGallery.css";

export default function ProductGallery({ images = [] }) {
  const [selectedImage, setSelectedImage] = useState(images?.[0] || "");

  useEffect(() => {
    setSelectedImage(images?.[0] || "");
  }, [images]);
  return (
    <div className="product-gallery-container">
      {/* Thumbnail Images */}
      <div className="thumbnail-container">
        {Array.isArray(images) &&
          images?.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index}`}
              className={`thumbnail ${
                selectedImage === image ? "selected" : ""
              }`}
              onClick={() => setSelectedImage(image)}
            />
          ))}
      </div>

      {/* Main Image with Magnifier */}
      <div className="main-image-container">
        {images.length > 0 ? (
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Product Image",
                isFluidWidth: true,
                src: selectedImage || images[0],
              },
              largeImage: {
                src: selectedImage || images[0],
                width: 1200,
                height: 1200,
              },
              enlargedImageContainerDimensions: {
                width: "150%",
                height: "100%",
              },
              enlargedImagePosition: "over",
              enlargedImageContainerStyle: {
                zIndex: 1000,
                backgroundColor: "white",
              },
              isHintEnabled: false,
            }}
          />
        ) : (
          <p className="no-image-text">No images available</p>
        )}
      </div>
    </div>
  );
}
