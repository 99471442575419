import { combineReducers } from "redux";
import user from "./userSlice";
import cart from "./cartSlice";
import category from "./categorySlice";

const rootReducer = combineReducers({
  user: user,
  cart: cart,
  category: category,
});

export default rootReducer;
