import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Image, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Utility from "../../Utils/Utility";
import {
  fetchCartAsync,
  removeCartItemAsync,
  updateCartAsync,
} from "../../Redux/Slice/cartSlice";
import AddressModal from "../Modals/AddressModal/AddressModal";
import useRazorpayCheckout from "../Checkout/useRazorpayCheckout ";
import emptyCart from "../../Assets/emptyCart.svg";
import { RxCross2 } from "react-icons/rx";
import { FaMinus, FaPlus } from "react-icons/fa6";
import MainLoader from "../../Globalcomponents/Loader/MainLoader";

export default function Cart() {
  const { cart, isCartLoading } = useSelector((state) => state?.cart);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { initiatePayment, isProcessing } = useRazorpayCheckout();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Dummy Handlers
  const handleUpdateQuantity = async (uniqueCartItemId, quantity) => {
    try {
      console.log("uniqueCartItemId", uniqueCartItemId);
      dispatch(
        updateCartAsync({
          uniqueCartItemId,
          quantity,
        })
      );
    } catch (error) {}
  };
  const removeItem = (uniqueCartItemId) => {
    dispatch(
      removeCartItemAsync({
        uniqueCartItemId,
      })
    );
  };
  const proceedToCheckout = async () => {
    if (!selectedAddress) {
      Utility.eToast("Please select an address to proceed");
      return;
    }
    // navigate("/checkout", { state: { selectedAddress } });
    await initiatePayment(selectedAddress);
    dispatch(fetchCartAsync());
  };

  return (
    <Container className="py-4">
      <div
        style={{
          minHeight: "60vh",
        }}
      >
        {cart?.items?.length > 0 ? (
          <>
            <Row>
              {/* Left Side - Cart Items */}
              <Col md={8}>
                {cart?.items?.map((item) => (
                  <Row
                    key={item?._id}
                    className="align-items-center mb-4 border-bottom pb-3"
                  >
                    <Col md={2}>
                      <Image
                        onClick={() =>
                          navigate(`/product-details/${item?.product?._id}`)
                        }
                        style={{
                          cursor: "pointer",
                        }}
                        src={item?.product?.images?.[0]}
                        fluid
                        rounded
                      />
                    </Col>
                    <Col md={4}>
                      <h5 className="mb-0">{item?.product?.name}</h5>
                    </Col>
                    <Col md={2}>
                      <h5>
                        {Utility.MoneyFormatter(
                          item?.product?.price?.toFixed(2) *
                            item?.requiredQuantity || 0
                        )}
                      </h5>
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => {
                          if (item?.requiredQuantity > 1) {
                            handleUpdateQuantity(
                              item?.uniqueCartItemId,
                              parseInt(item?.requiredQuantity) - 1
                            );
                          }
                        }}
                      >
                        <FaMinus />
                      </Button>
                      <span className="mx-3">{item?.requiredQuantity}</span>
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() =>
                          handleUpdateQuantity(
                            item?.uniqueCartItemId,
                            parseInt(item?.requiredQuantity) + 1
                          )
                        }
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                    <Col md={2}>
                      <Button
                        variant="link"
                        className="text-dark"
                        style={{
                          textDecoration: "none",
                          fontWeight: "500",
                          opacity: 0.7,
                        }}
                        onClick={() => removeItem(item?.uniqueCartItemId)}
                      >
                        <RxCross2 /> Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Col>

              {/* Right Side - Summary */}
              <Col md={4}>
                <div className="border p-3 rounded">
                  <h5>
                    Subtotal:{" "}
                    {Utility.MoneyFormatter(cart?.totalAmount.toFixed(2) || 0)}
                  </h5>
                  <p>Shipping Cost: Free</p>
                  {selectedAddress && (
                    <>
                      <p>Shipping Address</p>
                      <p>
                        {selectedAddress?.name +
                          ", " +
                          selectedAddress?.street +
                          ", " +
                          selectedAddress?.city +
                          ", " +
                          selectedAddress?.state +
                          ", " +
                          selectedAddress?.postalCode}
                      </p>
                    </>
                  )}

                  <Button
                    style={
                      {
                        // textDecoration: "none",
                        // color: "#111",
                        // fontWeight: "500",
                      }
                    }
                    color="primary"
                    variant="link"
                    onClick={() => setModalOpen(true)}
                  >
                    {selectedAddress ? "Change Address" : "Select an Address"}
                  </Button>

                  <hr />
                  <h4>
                    Total:
                    {Utility.MoneyFormatter(cart?.finalAmount.toFixed(2) || 0)}
                  </h4>
                  <Button
                    style={{
                      backgroundColor: "#111",
                      borderColor: "#ff9f00",
                    }}
                    className="w-100 my-3"
                    onClick={proceedToCheckout}
                  >
                    Pay Now
                  </Button>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: "#111",
                      fontWeight: "500",
                    }}
                  >
                    ← Continue Shopping
                  </Link>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs="auto">
              <Image src={emptyCart} fluid width={300} />
            </Col>
          </Row>
        )}
      </div>
      {modalOpen && (
        <AddressModal
          show={modalOpen}
          handleClose={() => setModalOpen(false)}
          onSelectAddress={(address) => {
            setSelectedAddress(address);
            setModalOpen(false);
          }}
        />
      )}
      {isCartLoading && <MainLoader />}
    </Container>
  );
}
