import React from "react";
import {
  FaTruck,
  FaShippingFast,
  FaGift,
  FaRedo,
  FaAward,
} from "react-icons/fa";

const Features = () => {
  const features = [
    { icon: <FaTruck />, text: "Cash On Delivery" },
    { icon: <FaShippingFast />, text: "Free Shipping" },
    { icon: <FaGift />, text: "Lowest Price" },
    { icon: <FaRedo />, text: "Return Policy" },
    { icon: <FaAward />, text: "Best Quality" },
  ];

  return (
    <div className="container py-4 mt-5">
      <div className="row justify-content-center">
        {features.map((feature, index) => (
          <div
            key={index}
            className="col-6 col-md-2 text-center d-flex align-items-center gap-2"
          >
            <span
              className="icon"
              style={{ fontSize: "1.4rem", color: "#333" }}
            >
              {feature.icon}
            </span>
            <span className="fw-semibold text-muted">{feature.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
