import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./CustomizeFurnitureCard.css";
import IMAGES from "./../../constants/image";

const products = [
  {
    image: IMAGES.userHome.flower,
    title: "Premium Wood Selections",
    description: "Handpicked quality wood for timeless furniture.",
  },
  {
    image: IMAGES.userHome.fabrics,
    title: "High-Quality Fabrics",
    description: "Luxurious textures for a perfect upholstery finish.",
  },
  {
    image: IMAGES.userHome.woodenChair,
    title: "Elegant Finishes",
    description: "Discover premium finishes that elevate your space.",
  },
  {
    image: IMAGES.userHome.furniture,
    title: "Stylish Designs",
    description: "Modern and classic styles for every home.",
  },
];

const CustomizeFurnitureCard = ({ handleShopNow }) => {
  return (
    <Row className="cfc-container">
      <div className="cfc-title-container">
        <h2 className="cfc-title">Customize Your Furniture</h2>
        <p className="cfc-subtitle">
          Crafted to perfection with your choice of materials and finishes.
        </p>
      </div>

      <Row className="cfc-card-row">
        {products.map((element, index) => (
          <Col sm={3} key={index} className="cfc-card-col">
            <Card className="cfc-card">
              <Card.Img
                variant="top"
                src={element.image}
                className="cfc-card-img"
              />
              <Card.Body className="cfc-card-body">
                <Card.Title className="cfc-card-title">
                  {element.title}
                </Card.Title>
                <Card.Text className="cfc-card-text">
                  {element.description}
                </Card.Text>
                <div className="cfc-action" onClick={handleShopNow}>
                  <span className="cfc-explore-text">Explore More</span>
                  <FaLongArrowAltRight className="cfc-arrow-icon" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Row>
  );
};

export default CustomizeFurnitureCard;
