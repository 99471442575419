import React, { useState, useEffect } from "react";
import { Modal, Button, Form, ListGroup, Row, Col } from "react-bootstrap";
import { CartController } from "../../../Redux/controllers/CartController";
import MainLoader from "./../../../Globalcomponents/Loader/MainLoader";
import Loader from "../../../Globalcomponents/Loader/Loader";

const AddressModal = ({ show, handleClose, onSelectAddress }) => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "India",
    isDefault: false,
  });

  useEffect(() => {
    if (show) fetchAddresses();
  }, [show]);

  const fetchAddresses = async () => {
    try {
      setLoading(true);
      const { data } = await CartController.fetchAddresses();
      setAddresses(data?.addresses || []);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.phone.trim()) errors.phone = "Phone number is required";
    if (!formData.street.trim()) errors.street = "Street is required";
    if (!formData.city.trim()) errors.city = "City is required";
    if (!formData.state.trim()) errors.state = "State is required";
    if (!formData.postalCode.trim())
      errors.postalCode = "Postal Code is required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddOrUpdateAddress = async () => {
    if (!validateForm()) return;
    setUpdateLoading(true);
    try {
      if (isEditing) {
        const { data } = await CartController.updateAddress(
          editData._id,
          formData
        );
        setAddresses(
          addresses.map((addr) =>
            addr._id === editData._id ? data.address : addr
          )
        );
      } else {
        const { data } = await CartController.addAddress(formData);
        setAddresses([...addresses, data.address]);
      }
      resetForm();
    } catch (error) {
      console.error("Error saving address:", error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleEditAddress = (address) => {
    setIsEditing(true);
    setEditData(address);
    setFormData(address);
    setShowAddAddress(true);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      phone: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "India",
      isDefault: false,
    });
    setShowAddAddress(false);
    setIsEditing(false);
    setEditData(null);
  };

  const handleSelectAddress = (addressId) => {
    setSelectedAddress(addressId);
    const selected = addresses.find((addr) => addr._id === addressId);
    if (selected) onSelectAddress(selected);
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {showAddAddress
            ? isEditing
              ? "Edit Address"
              : "Add an Address"
            : "Select an Address"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              width: "100%",
            }}
          >
            <Loader />
          </div>
        ) : showAddAddress ? (
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.name}
                    isInvalid={!!formErrors.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.phone}
                    isInvalid={!!formErrors.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Street</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.street}
                    isInvalid={!!formErrors.street}
                    onChange={(e) =>
                      setFormData({ ...formData, street: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.street}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.city}
                    isInvalid={!!formErrors.city}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.state}
                    isInvalid={!!formErrors.state}
                    onChange={(e) =>
                      setFormData({ ...formData, state: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.state}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.postalCode}
                    isInvalid={!!formErrors.postalCode}
                    onChange={(e) =>
                      setFormData({ ...formData, postalCode: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.postalCode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mt-2">
              <Form.Check
                type="checkbox"
                label="Set as Default"
                checked={formData.isDefault}
                onChange={(e) =>
                  setFormData({ ...formData, isDefault: e.target.checked })
                }
              />
            </Form.Group>

            <div className="d-flex justify-content-end gap-3 mt-3">
              <Button variant="secondary" onClick={resetForm}>
                Back
              </Button>
              <Button
                style={{ backgroundColor: "#111", border: "none" }}
                onClick={handleAddOrUpdateAddress}
              >
                {isEditing ? "Update Address" : "Add Address"}
              </Button>
            </div>
          </Form>
        ) : (
          <>
            <ListGroup>
              {addresses.map((address) => (
                <ListGroup.Item key={address._id} action>
                  <div onClick={() => handleSelectAddress(address._id)}>
                    <strong>{address.name}</strong> ({address.phone}) <br />
                    {address.street}, {address.city}, {address.state} -{" "}
                    {address.postalCode}, {address.country}
                  </div>
                  <Button
                    variant="link"
                    style={{ color: "#111", fontSize: "0.9rem" }}
                    onClick={() => handleEditAddress(address)}
                  >
                    Edit
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button
              onClick={() => setShowAddAddress(true)}
              variant="link"
              style={{ color: "#111" }}
              className="my-2"
            >
              Add New Address
            </Button>
          </>
        )}
        {updateLoading && <MainLoader />}
      </Modal.Body>
    </Modal>
  );
};

export default AddressModal;
