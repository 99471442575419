import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebookF,
  FaXTwitter,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa6";
import "./MainFooter.css";
import IMAGES from "./../../constants/image";
import { IoIosCall } from "react-icons/io";
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const { categories, categoryLoading } = useSelector(
    (state) => state.category
  );
  return (
    <footer className="footer">
      <Container>
        <Row className="footer-top">
          {/* Branding & Address */}
          <Col lg={4} md={6} className="d-flex flex-column ">
            <div className="d-flex align-items-center gap-2">
              <img
                src={IMAGES.jbsWhiteLogo}
                width={40}
                className="d-block"
                alt=""
              />
              <h4
                className="brand-name"
                style={{
                  fontWeight: 600,
                  fontFamily: "var(--inter)",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                JBS Interior Services
              </h4>
            </div>
            <p
              style={{
                marginTop: "auto",
              }}
            >
              2Q34+7XC, Nagarathnamma Layout, Belathur, Kadugodi, Bengaluru,
              Karnataka 560067
            </p>
          </Col>

          {/* Quick Links */}
          {/* <Col lg={2} md={6} className="footer-links">
            <h5>Quick Links</h5>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">About Us</a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
              <li>
                <a href="#">Design Story</a>
              </li>
              <li>
                <a href="#">Gallery</a>
              </li>
              <li>
                <a href="#">Careers</a>
              </li>
              <li>
                <a href="#">Blogs</a>
              </li>
            </ul>
          </Col> */}

          {/* Products */}
          <Col lg={5} md={6} className="footer-links">
            <h5>Product Categories</h5>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1rem",
              }}
            >
              {categories?.slice(0, 8)?.map((item, index) => {
                return (
                  <Link
                    to={"/category/" + item?._id}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    style={{
                      width: "fit-content",
                    }}
                  >
                    {item?.name}
                  </Link>
                );
              })}
            </div>
          </Col>

          {/* Contact Info */}
          <Col lg={3} md={6} className="footer-contact">
            <h5>Contact Us</h5>
            <p className="d-flex align-items-center gap-2">
              <IoIosCall />
              (+91) 8050141583
            </p>
            <p className="d-flex align-items-center gap-2">
              <MdMail />
              newbusiness@mailstone.co.in
            </p>

            <div className="social-icons mt-5">
              <a
                href="#"
                target="_blank"
                rel="noreferrer"
                id="footer-link-icon-bg"
              >
                <FaFacebookF />
              </a>
              <a href="#" target="_blank" rel="noreferrer">
                <FaXTwitter />
              </a>
              <a
                href="https://www.youtube.com/@JBSInteriors"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size={25} />
              </a>
              <a
                href="https://www.instagram.com/mailstonepltd2022"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </div>
          </Col>
        </Row>

        {/* Copyright */}
        <Row className="footer-bottom">
          <Col>
            <p>© 2024 JBS Interior Services. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
