import React, { useEffect, useState } from "react";
import "./ProductDetails.css";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserController } from "../../Redux/controllers/UserController";
import { BsBagFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { addToCartAsync } from "../../Redux/Slice/cartSlice";
import ProductGallery from "./ProductGallery";
import BreadCrumbComp from "../../Globalcomponents/BreadCrumbComp/BreadCrumbComp";
import MainLoader from "../../Globalcomponents/Loader/MainLoader";
import Utility from "../../Utils/Utility";
import { IoIosLock } from "react-icons/io";

const ProductDetails = () => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedPattern, setSelectedPattern] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [productDetails, setProductDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { isCartLoading } = useSelector((state) => state.cart);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { productId } = useParams();

  const getProductDetails = async () => {
    setLoading(true);
    try {
      const response = await UserController.getProductCategoryById(productId);
      setProductDetails(response?.data?.data || {});
    } catch (error) {
      setErrorMessage("Failed to fetch product details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) getProductDetails();
  }, [productId]);

  const handleAddToCart = () => {
    if (!selectedSize && !selectedColor && !selectedPattern) {
      Utility.eToast(
        "Please select Size, Color, and Pattern before adding to cart."
      );
      return;
    } else if (!selectedSize) {
      Utility.eToast("Please select a size.");
      return;
    } else if (!selectedColor) {
      Utility.eToast("Please select a color.");
      return;
    } else if (!selectedPattern) {
      Utility.eToast("Please select a pattern.");
      return;
    }

    if (requiredQuantity < 1) {
      Utility.eToast("Please enter a valid quantity.");
      return;
    }

    dispatch(
      addToCartAsync({
        productId: productDetails._id,
        selectedSize,
        quantity: parseInt(requiredQuantity),
        selectedColor,
        selectedPattern,
        additionalInfo,
        storageFeaturesIncluded: false,
      })
    );
  };

  return (
    <Container className="prd-details-container">
      {loading ? (
        <div style={{ minHeight: "60vh" }}>
          <MainLoader />
        </div>
      ) : (
        <>
          <BreadCrumbComp productName={productDetails?.name || "PRODUCT"} />

          <Row>
            {/* Left Section: Product Gallery */}
            <Col sm={7} md={7}>
              <ProductGallery images={productDetails?.images || []} />

              <div className="prd-details-section">
                <h1 className="prd-details-title">{productDetails?.name}</h1>
                {productDetails?.description && (
                  <p className="prd-details-description">
                    {productDetails?.description}
                  </p>
                )}
              </div>
              {Object.entries({
                Features: productDetails?.keyFeatures,
                Materials: productDetails?.materialInfo,
                "Included Components": productDetails?.includedComponents,
                Certifications: productDetails?.certifications,
              }).map(([title, content]) => {
                return (
                  <>
                    {content && (
                      <div key={title} className="prd-details-features">
                        <h2 className="prd-details-heading">{title}</h2>
                        {typeof content === "string"
                          ? Utility.HTMLParser(content)
                          : content}
                      </div>
                    )}
                  </>
                );
              })}
            </Col>

            {/* Right Section: Product Customization */}
            <Col sm={5} md={5} xs={12}>
              <div className="prd-details-customization">
                {/* Choose Color */}
                <div className="prd-details-section-spacer">
                  <h2 className="prd-details-section-title">Choose Color</h2>
                  <div className="prd-details-color-options">
                    {productDetails?.color?.map((color, idx) => (
                      <div
                        key={idx}
                        className={`prd-details-color-item ${
                          selectedColor === color
                            ? "prd-details-color-selected"
                            : ""
                        }`}
                        onClick={() => setSelectedColor(color)}
                      >
                        <div
                          className="prd-details-color-circle"
                          style={{ backgroundColor: color }}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Available Size */}
                <div className="prd-details-section-spacer">
                  <h2 className="prd-details-section-title">Available Size</h2>
                  <div className="prd-details-size-options">
                    {productDetails?.size?.map((size, idx) => (
                      <div
                        key={idx}
                        className={`prd-details-size-item ${
                          selectedSize === size
                            ? "prd-details-size-selected"
                            : ""
                        }`}
                        onClick={() => setSelectedSize(size)}
                      >
                        {size}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Select Pattern */}
                <div className="prd-details-section-spacer">
                  <h2 className="prd-details-section-title">Select Pattern</h2>
                  <div className="prd-details-pattern-options">
                    {productDetails?.pattern?.map((pattern, idx) => (
                      <div
                        key={idx}
                        className={`prd-details-pattern-item ${
                          selectedPattern === pattern
                            ? "prd-details-pattern-selected"
                            : ""
                        }`}
                        onClick={() => setSelectedPattern(pattern)}
                      >
                        <img
                          className="prd-details-pattern-img"
                          src={pattern}
                          alt="Pattern"
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Quantity Input */}
                <div className="prd-details-section-spacer">
                  <h2 className="prd-details-section-title">Quantity</h2>
                  <input
                    type="number"
                    className="prd-details-input-field"
                    name="requiredQuantity"
                    placeholder="1"
                    min="1"
                    onChange={(e) => setRequiredQuantity(e.target.value)}
                  />
                </div>

                {/* Price & Add to Cart */}
                <div className="prd-details-purchase">
                  <p className="prd-details-price">
                    ₹{productDetails?.price?.toLocaleString()}
                  </p>

                  {token ? (
                    <button
                      disabled={isCartLoading}
                      onClick={handleAddToCart}
                      className="prd-details-cart-btn"
                      style={{ opacity: isCartLoading ? 0.5 : 1 }}
                    >
                      <BsBagFill />
                      Add to Cart
                    </button>
                  ) : (
                    <button
                      className="prd-details-cart-btn"
                      onClick={() => {
                        navigate("/login");
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <IoIosLock size={25} />
                      Please Login to Continue
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ProductDetails;
