import React from "react";
import { Row, Col } from "react-bootstrap";
import "./ShopByCategoryCard.css";
import { Link } from "react-router-dom";
import IMAGES from "../../constants/image";

const cards = [
  {
    image: IMAGES.userHome.bed,
    title: "Beds & Mattresses",
    tagline: "Sleep in luxury with premium comfort.",
  },
  {
    image: IMAGES.userHome.sofa,
    title: "Sofas & Couches",
    tagline: "Elegant & cozy seating for every home.",
  },
  {
    image: IMAGES.userHome.kitchen,
    title: "Kitchen & Appliances",
    tagline: "Upgrade your kitchen with smart solutions.",
  },
  {
    image: IMAGES.userHome.interior,
    title: "Interior Essentials",
    tagline: "Transform your space with modern designs.",
  },
];

const ShopByCategoryCard = () => {
  return (
    <Row className="sbcc-container">
      <div className="sbcc-title-container">
        <h2 className="sbcc-title">Shop By Category</h2>
        <p className="cfc-subtitle">
          Explore our range of products for every room in your home.
        </p>
      </div>

      {cards.map((element, index) => (
        <Col key={index} sm={3} className="sbcc-card-col">
          <Link
            to="/category"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            className="sbcc-card-link"
          >
            <div className="sbcc-card">
              <img
                src={element.image}
                alt={element.title}
                className="sbcc-card-img"
              />
              <div className="sbcc-overlay">
                <div className="sbcc-text">
                  <h4 className="sbcc-category-title">{element.title}</h4>
                  <p className="sbcc-tagline">{element.tagline}</p>
                </div>
              </div>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default ShopByCategoryCard;
