import React from "react";
import "./BentoGridBranding.css";
import IMAGES from "../../constants/image";

const BentoGrid = () => {
  const items = [
    {
      id: 1,
      image: IMAGES.photo1,
      title: "Kitchen Space",
      description: "Luxurious kitchens",
    },
    {
      id: 2,
      image: IMAGES.photo2,
      title: "Office Space",
      description: "Comfortable workspaces",
    },
    {
      id: 3,
      image: IMAGES.photo3,
      title: "Cozy Bedroom",
      description: "Peaceful retreats",
    },
    {
      id: 4,
      image: IMAGES.photo4,
      title: "Sofa Comfort",
      description: "Comfortable living rooms",
    },
  ];

  return (
    <>
      <div className="bento-grid">
        {/* X Section (60%) */}
        <div className="bento-x-section ">
          <div className="bento-item x1">
            <img
              src={items[0].image}
              className="bento-image"
              alt={items[0].title}
            />
            <div className="bento-overlay">
              <h4>{items[0].title}</h4>
              <p>{items[0].description}</p>
            </div>
          </div>
          <div className="bento-item x2">
            <img
              loading="lazy"
              src={items[1].image}
              className="bento-image"
              alt={items[1].title}
            />
            <div className="bento-overlay">
              <h4>{items[1].title}</h4>
              <p>{items[1].description}</p>
            </div>
          </div>

          {/* <div className="bento-x2">
            {items.slice(1, 3).map((item) => (
              <div className="bento-item" key={item.id}>
                <img
                  src={item.image}
                  className="bento-image"
                  alt={item.title}
                />
                <div className="bento-overlay">
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div> */}
        </div>

        {/* Y Section (40%) */}
        <div className="bento-y-section">
          <div className="bento-item y1">
            <img
              src={items[2].image}
              className="bento-image"
              alt={items[2].title}
            />
            <div className="bento-overlay">
              <h4>{items[2].title}</h4>
              <p>{items[2].description}</p>
            </div>
          </div>
          <div className="bento-item y2">
            <img
              src={items[3].image}
              className="bento-image"
              alt={items[3].title}
            />
            <div className="bento-overlay">
              <h4>{items[3].title}</h4>
              <p>{items[3].description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BentoGrid;
