import axios from "axios";
// import store from "./../store/store";
// import { logOut } from "../Slice/userSlice";
import React from "react";

const API_BASE_URL = "https://jbsapi.mailstone.co.in/api/v1/";
// const API_BASE_URL = "https://jbs-backend-9oni.onrender.com/api/v1/";
// const API_BASE_URL = "http://192.168.29.29:8080/api/v1/";
// const API_BASE_URL = "http://172.29.224.1:8080/api/v1/";

// Create axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
});

// Request Interceptor
api.interceptors.request.use(
  (config) => {
    // Modify request before it is sent, e.g., add token to headers
    const token = localStorage.getItem("user-token"); // Or get token from anywhere else
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors here (optional)
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Response Interceptor (Handles Token Expiry & Invalid Token)
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("Response error:", error.response);

      // Check if error is due to token expiration or invalid token
      if (error.response.status === 401 || error.response.status === 403) {
        console.warn("Token expired or invalid! Logging out...");

        // Clear local storage
        localStorage.removeItem("user-token");
        localStorage.removeItem("user-data");

        // Dispatch Redux logout action (clears Redux state)
        // store.dispatch(logOut());

        // Redirect to login page
        // window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

const apiCaller = (
  uri,
  method = "GET",
  data = {},
  token,
  contentType = null
) => {
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: API_BASE_URL + uri,
      headers: {
        "Content-Type": contentType ? contentType : "application/json",
        accept: "*/*",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    api(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const deleteApicaller = (uri, token) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: API_BASE_URL + uri,
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    api(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const HttpClient = { apiCaller, API_BASE_URL, deleteApicaller };
