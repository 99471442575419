import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import "./MainContent.css";
import IMAGES from "../../constants/image";

const MainContent = ({ handleShopNow }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 576);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row className="main-content-first-row mb-5">
      <Col sm={6} className={isMobile ? "mb-4" : ""}>
        <div className="main-content-hero-container">
          <Container className="main-content-hero-content rounded-4 h-100">
            <div className="main-content-hero-text">
              <h1 className="main-content-heading">Elevating Homes,</h1>
              <h1 className="main-content-heading-accent">
                One Piece at a Time.
              </h1>
              <p className="main-content-subheading mt-3">
                Transform your space with our curated collection
              </p>
            </div>
            <div className="main-content-cta-container">
              <button
                className="main-content-shop-button"
                onClick={handleShopNow}
              >
                <span>Shop Now</span>
                <FaLongArrowAltRight className="main-content-arrow-icon" />
              </button>
            </div>
          </Container>
        </div>
      </Col>

      <Col sm={3} className={isMobile ? "mb-4" : ""}>
        <div className="main-content-image-container">
          <img
            className="main-content-feature-image rounded-4"
            alt="Featured furniture"
            src={IMAGES.userHome.f1}
          />
        </div>
      </Col>

      <Col sm={3} className="main-content-image-stack">
        <div className="main-content-image-stack-container">
          <div
            className={`main-content-stack-item ${isMobile ? "mb-4" : "mb-3"}`}
          >
            <img
              className="main-content-feature-image rounded-4"
              alt="Featured design"
              src={IMAGES.userHome.f2}
            />
          </div>
          <div className="main-content-stack-item">
            <img
              className="main-content-feature-image rounded-4"
              alt="Featured interior"
              src={IMAGES.userHome.f3}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MainContent;
