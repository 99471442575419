// ProgressStepper.jsx
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  MdPendingActions,
  MdCheckCircle,
  MdInventory,
  MdLocalShipping,
  MdDomainVerification,
  MdCheck,
} from "react-icons/md";
import "./ProgressStepper.css";

const STEPS = {
  pending: {
    icon: MdPendingActions,
    description: "Order is pending",
    label: "Pending",
  },
  confirmed: {
    icon: MdCheckCircle,
    description: "Order confirmed",
    label: "Confirmed",
  },
  processing: {
    icon: MdInventory,
    description: "Processing order",
    label: "Processing",
  },
  shipped: {
    icon: MdLocalShipping,
    description: "Order in transit",
    label: "Shipped",
  },
  delivered: {
    icon: MdDomainVerification,
    description: "Order delivered",
    label: "Delivered",
  },
};

const ProgressStepper = ({ ProgressData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = Object.keys(STEPS);

  useEffect(() => {
    if (ProgressData?.deliveryStatus) {
      const stepIndex = steps.indexOf(ProgressData.deliveryStatus);
      setActiveStep(stepIndex !== -1 ? stepIndex : 0);
    }
  }, [ProgressData, steps]);

  const renderStepIcon = (step, isCompleted) => {
    // If step is completed, show check icon
    if (isCompleted) {
      return <MdCheck />;
    }
    // Otherwise show the step's icon
    const IconComponent = STEPS[step].icon;
    return <IconComponent />;
  };

  const renderStep = (step, index) => {
    const isActive = index <= activeStep;
    const isCompleted = index < activeStep;

    return (
      <Col key={step} className="text-center position-relative">
        {/* Connector Line */}
        {index < steps.length - 1 && (
          <div
            className={`connector-line position-absolute ${
              isCompleted ? "completed" : ""
            }`}
            role="presentation"
          />
        )}

        {/* Step Circle with Icon */}
        <div
          className={`step-circle mx-auto ${isActive ? "active" : ""} ${
            isCompleted ? "completed" : ""
          }`}
          role="status"
          aria-current={index === activeStep ? "step" : undefined}
        >
          {renderStepIcon(step, isCompleted)}
        </div>

        {/* Step Label and Description */}
        <div className={isActive ? "text-primary" : "text-muted"}>
          <div className="step-label">{STEPS[step].label}</div>
          <div className="step-description">{STEPS[step].description}</div>
        </div>
      </Col>
    );
  };

  return (
    <div
      className="progress-tracker"
      role="progressbar"
      aria-valuenow={activeStep + 1}
      aria-valuemin={1}
      aria-valuemax={steps.length}
    >
      <Row className="position-relative">{steps.map(renderStep)}</Row>
    </div>
  );
};

ProgressStepper.propTypes = {
  ProgressData: PropTypes.shape({
    deliveryStatus: PropTypes.oneOf(Object.keys(STEPS)),
  }),
};

ProgressStepper.defaultProps = {
  ProgressData: {
    deliveryStatus: "pending",
  },
};

export default ProgressStepper;
