import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UserController } from "../../Redux/controllers/UserController";
import ProductCard from "./ProductCard";
import MainLoader from "../../Globalcomponents/Loader/MainLoader";

export default function CategoryProducts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { categoryId } = useParams();

  const getAllProductsByCategory = () => {
    setLoading(true);
    UserController.getProductByCategory(categoryId)
      .then((res) => {
        setAllProducts(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (categoryId) {
      getAllProductsByCategory();
    } else {
      navigate("/category");
    }
  }, [categoryId]);
  return (
    <div
      style={{
        minHeight: "50vh",
      }}
    >
      {loading ? (
        <MainLoader />
      ) : (
        <>
          {allProducts?.length === 0 ? (
            <div>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  margin: "1rem 0",
                  fontWeight: 500,
                }}
              >
                sorry, no products available for this category
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  margin: "1rem 0",
                  fontWeight: 400,
                }}
              >
                We are working on adding more products to this category
              </p>
            </div>
          ) : (
            <div className="landingLstRow">
              {allProducts?.map((item, index) => (
                <ProductCard item={item} key={index} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
