import React, { useState, useEffect, useCallback } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signUp, userSelfRegister } from "../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { UserController } from "../../Redux/controllers/UserController";
import Utility from "../../Utils/Utility";
import "../Login/Login.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import IMAGES from "../../constants/image";
import MainLoader from "../../Globalcomponents/Loader/MainLoader";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [validated, setValidated] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleInputChange = useCallback(
    (setter) => (event) => {
      setter(event.target.value);
    },
    []
  );

  const sendOtpToUser = async () => {
    if (!mobileNo || mobileNo.length !== 10) {
      Utility.eToast("Enter a valid 10-digit mobile number");
      return;
    }

    if (otpSent) {
      Utility.eToast("OTP already sent. Please wait.");
      return;
    }

    try {
      setOtpSent(true);
      setCountdown(60);

      const res = await UserController.sendOtp({ phoneNo: mobileNo });
      Utility.sToast(`Your OTP is ${res.data.data}`);

      // Start the countdown
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setOtpSent(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      console.error("Error in sending OTP:", error);
      setOtpSent(false);
      setCountdown(0);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setValidated(true);

    if (!name || !email || !mobileNo || mobileNo.length !== 10 || !otp) {
      return;
    }

    setSpin(true);
    try {
      await dispatch(
        userSelfRegister({ name, email, phoneNo: mobileNo, otp })
      ).then(unwrapResult);
      // navigate("/dashboard");
    } catch (error) {
      console.error("Sign up error:", error);
    }
    setSpin(false);
  };
  if (spin) {
    return <MainLoader />;
  }
  return (
    <Container className="loginContainer" fluid>
      <div id="signupDiv">
        <Button
          variant="link"
          style={{
            textDecoration: "none",
            marginLeft: "-1.5rem",
          }}
          onClick={() => navigate("/")}
        >
          <FaArrowLeftLong /> Back to Home
        </Button>

        <div className="text-center">
          <img id="mmIcon" src={IMAGES.jbsWithNameLogo} alt="Logo" />
        </div>
        {/* <h2 id="loginHeading" className="text-center">
          User Sign Up
        </h2> */}

        <Form noValidate validated={validated} onSubmit={handleSignUp}>
          {/* Name Input */}
          <Form.Group className="mb-3">
            <Form.Label className="label">Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Full Name"
              className="input"
              value={name}
              onChange={handleInputChange(setName)}
              isInvalid={validated && !name}
              required
            />
            <Form.Control.Feedback type="invalid" aria-live="polite">
              Enter a valid name.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Email Input */}
          <Form.Group className="mb-3">
            <Form.Label className="label">Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Your Email"
              className="input"
              value={email}
              onChange={handleInputChange(setEmail)}
              isInvalid={validated && !email}
              required
            />
            <Form.Control.Feedback type="invalid" aria-live="polite">
              Enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Mobile Number Input */}
          <Form.Group className="mb-1">
            <Form.Label className="label">Mobile Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Your Mobile Number"
              className="input"
              value={mobileNo}
              onChange={handleInputChange(setMobileNo)}
              isInvalid={validated && (!mobileNo || mobileNo.length !== 10)}
              required
            />
            <Form.Control.Feedback type="invalid" aria-live="polite">
              Enter a valid 10-digit mobile number.
            </Form.Control.Feedback>
            <p
              className={`otpBox mt-lg-1  ${otpSent ? "disabled" : ""}`}
              onClick={!otpSent ? sendOtpToUser : undefined}
              style={{ cursor: otpSent ? "not-allowed" : "pointer" }}
            >
              {otpSent ? `Resend OTP in ${countdown}s` : "Send OTP"}
            </p>
          </Form.Group>

          {/* OTP Input */}
          <Form.Group className="mb-1">
            <Form.Label className="label">OTP</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter OTP"
              className="input"
              value={otp}
              onChange={handleInputChange(setOtp)}
              isInvalid={validated && !otp}
              required
            />
            <Form.Control.Feedback type="invalid" aria-live="polite">
              Enter the OTP received on your mobile.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Sign Up Button */}
          <Row className="d-flex flex-column justify-content-between">
            <Col className="d-grid">
              <Button
                disabled={spin || otp.length !== 6}
                type="submit"
                size="lg"
                id="loginBtn"
              >
                Sign Up
              </Button>
            </Col>
            <Col className="d-grid">
              <Button
                size="lg"
                variant="link"
                style={{ textDecoration: "none", fontSize: "1rem" }}
                onClick={() => navigate("/login")}
              >
                Already have an account? Log In
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default SignUp;
