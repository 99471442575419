import Modal from "react-bootstrap/Modal";
import { FaDownload, FaTimes } from "react-icons/fa";
import "./OrderModal.css";
import Utility from "../../../Utils/Utility";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressStepper from "../../../Globalcomponents/ProgressBar/ProgressStepper";

function OrderModal(props) {
  const { product: productData, ...modalProps } = props;
  const { token } = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileDownload = async () => {
    const id = productData?._id;
    try {
      setLoading(true);
      const resp = await UserController.downloadYourInvoice(id, token);
      const cloudinaryUrl = resp.data.data;
      if (cloudinaryUrl) {
        const newWindow = window.open(cloudinaryUrl, "_blank");
        if (
          !newWindow ||
          newWindow.closed ||
          typeof newWindow.closed == "undefined"
        ) {
          alert("Popup blocked. Please allow popups for this website.");
        }
      }
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      {...modalProps}
      size="lg"
      aria-labelledby="order-modal"
      className="om-modal"
    >
      <div className="om-modal-content">
        <div className="om-modal-header">
          <h4>Order Details</h4>
          <button className="om-close-btn" onClick={props.onHide}>
            <FaTimes />
          </button>
        </div>

        <div className="om-modal-body">
          <div className="om-order-summary">
            <div className="om-order-info">
              <div className="om-order-id">Order #{productData?.orderID}</div>
              <div className="om-order-amount">
                {Utility.MoneyFormatter(
                  productData?.paymentDetails?.totalAmount
                )}
              </div>
            </div>

            <div className="om-order-address">
              <h6>Delivery Address</h6>
              <p>
                {productData?.customerDetails?.shippingAddress?.slice(0, 150)}
                {productData?.customerDetails?.shippingAddress?.length > 150
                  ? "..."
                  : ""}
              </p>
            </div>

            <button
              className="om-download-btn"
              onClick={handleFileDownload}
              disabled={loading}
            >
              <FaDownload /> Download Invoice
            </button>
          </div>

          <div className="om-progress-section">
            <ProgressStepper ProgressData={productData} />
          </div>

          <div className="om-products-section">
            <h5>Ordered Items</h5>
            <div className="om-products-list">
              {productData?.productDetails?.map((product, index) => (
                <div
                  key={index}
                  className="om-product-card"
                  onClick={() =>
                    navigate(`/product-details/${product?.product?._id}`)
                  }
                >
                  <div className="om-product-image">
                    <img
                      src={product?.product?.images[0]}
                      alt={product?.product?.name}
                    />
                  </div>
                  <div className="om-product-details">
                    <h6>
                      {product?.product?.name?.slice(0, 50)}
                      {product?.product?.name?.length > 50 ? "..." : ""}
                    </h6>
                    <span className="om-product-id">
                      ID: {product?.product?.productID}
                    </span>
                    <div className="om-product-desc">
                      {Utility.HTMLParser(
                        product?.product?.description?.slice(0, 100)
                      )}
                      {product?.product?.description?.length > 100 ? "..." : ""}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default OrderModal;
