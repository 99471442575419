import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import Features from "./Features";
import BentoGridBranding from "./BentoGridBranding";
import IMAGES from "../../constants/image";

const CategoryLanding = () => {
  return (
    <>
      <BentoGridBranding />

      <div className="landing-line" />

      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          backgroundColor: "#f9f9f9",
          borderRadius: "10px",
          marginTop: "2rem",
        }}
      >
        <img className="frameimage" src={IMAGES.photo5} alt="frame Image" />
        <div className="frametext">
          <h3 className="">New lower price</h3>
          <p>
            We've just reduced the prices of our favorite products, making them
            even more affordable. Take advantage of these lowered prices and
            discover a wide range of options.
          </p>
        </div>
      </div>

      <Features />
    </>
  );
};

export default CategoryLanding;
