import React from "react";
import RootRouter from "./Navigations/RootRouter";
import toast, { Toaster } from "react-hot-toast";
import ContactusModal from "./pages/Modals/ContactusModal/ContactusModal";
import { TbMessageMinus } from "react-icons/tb";
import { BrowserRouter, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleContactUsModal } from "./Redux/Slice/userSlice";

export default function App() {
  // const [showContactModal, setShowContactModal] = useState(false);
  const dispatch = useDispatch();
  const handleContactUs = () => {
    dispatch(toggleContactUsModal());
  };
  const { showContactUsModal } = useSelector((state) => state.user);
  const location = useLocation();

  const showContactIcon = () => {
    return !["/login", "/signup", "/cart"].includes(location.pathname);
  };

  return (
    <>
      <RootRouter />
      {showContactUsModal && (
        <ContactusModal show={showContactUsModal} onHide={handleContactUs} />
      )}
      {showContactIcon() && (
        <div id="msgBtn" onClick={handleContactUs}>
          <TbMessageMinus className="MessageIconContainer" />
        </div>
      )}
      <Toaster />
    </>
  );
}
