import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container, Button } from "react-bootstrap";
import "./LogoutModal.css";

const LogoutModal = (props) => {
  const [value, setValue] = useState("");
  const handelSubmit = (e) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
    props.onConfirm(selectedValue);
    props.onHide();
  };
  console.log("Value", value);

  return (
    <Modal id="transactionModal" {...props} size="md">
      <Modal.Header closeButton style={{ background: "#fff" }}>
        <Modal.Title style={{ color: "#fff", background: "" }}></Modal.Title>
      </Modal.Header>
      <Modal.Body className="Container">
        <Row>
          <Col>
            <h5> Are you sure to logout ?</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={handelSubmit}
              // className="BUTTON"
              value="cancel"
              variant="outline-dark"
              style={{
                width: "5rem",
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              variant="dark"
              onClick={handelSubmit}
              //   className="BUTTON"
              style={{
                width: "5rem",
              }}
              value="ok"
            >
              OK
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
