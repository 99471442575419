import React, { useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoMdCart } from "react-icons/io";
import { FiSearch, FiUser, FiShoppingCart } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { IoPower } from "react-icons/io5";
import { logOut } from "../../Redux/Slice/userSlice";
import LogoutModal from "./../../pages/Modals/LogoutModal/LogoutModal";
import IMAGES from "../../constants/image";
import { GrHomeRounded } from "react-icons/gr";
import Utility from "../../Utils/Utility";

function MainNavbar() {
  const [expanded, setExpanded] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.user?.token);
  const { cart } = useSelector((state) => state?.cart);

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };
  const closeNavLink = () => {
    setExpanded((prev) => prev && false);
  };

  const handleUserIconClick = () => {
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };
  const handleLogout = () => {
    try {
      dispatch(logOut());
      Utility.sToast("Logged out Successfully");
    } catch (error) {}
  };
  const handleConfirmLogout = (value) => {
    if (value === "ok") {
      handleLogout();
    }
  };
  return (
    <Navbar
      expand="lg"
      fixed="top"
      bg="white"
      expanded={expanded}
      className="shadow-sm "
      collapseOnSelect
    >
      <Container fluid="lg">
        <Navbar.Brand as={Link} to="/">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <img
              src={IMAGES.jbsBrandLogo}
              alt="Logo"
              width={140}
              style={{ display: "block" }} // Ensures no inline spacing
            />
            {/* <p
              className="jbs-interior-name"
              style={{
                fontWeight: 600,
                fontFamily: "var(--inter)",
                margin: 0, // Removes unwanted space
                display: "flex",
                alignItems: "center",
              }}
            >
              Jbs Interior
            </p> */}
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbar-nav" onClick={toggleExpand} />

        <Navbar.Collapse id="navbar-nav">
          <Nav className="gap-3 ms-auto">
            {/* <Nav.Link
              className=" "
              onClick={() => {
                closeNavLink();
              }}
            >
              <FiSearch size={20} />
            </Nav.Link> */}
            <Nav.Link
              to="/"
              className=" "
              onClick={() => {
                navigate("/");
                closeNavLink();
              }}
            >
              <GrHomeRounded size={20} />
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                closeNavLink();
                handleUserIconClick();
              }}
              className=" "
            >
              <FiUser size={20} />
            </Nav.Link>
            <Nav.Link
              as={Link}
              onClick={closeNavLink}
              to="/cart"
              className="position-relative "
            >
              <FiShoppingCart size={20} />
              {cart?.items?.length > 0 && (
                <span className="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-black">
                  {cart?.items?.length}
                </span>
              )}
            </Nav.Link>
            {token && (
              <Nav.Link
                onClick={() => {
                  closeNavLink();
                  setShowLogoutModal(true);
                }}
                className=""
              >
                <IoPower size={20} />
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
        {showLogoutModal && (
          <LogoutModal
            show={showLogoutModal}
            onHide={() => {
              setShowLogoutModal(false);
            }}
            onConfirm={handleConfirmLogout}
          />
        )}
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
