import React, { useState } from "react";
import "./ProductCard.css";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ item }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className="product-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Product Image with Background */}
      <div className="product-box">
        <img className="product-image" src={item?.images[0]} alt={item?.name} />

        {/* Add to Cart Button - Appears on Hover */}
        <button
          onClick={() => navigate(`/product-details/${item._id}`)}
          className={`add-to-cart-btn ${isHovered ? "show" : ""}`}
        >
          View Details
          <FaArrowUpRightFromSquare />
        </button>
      </div>

      {/* Product Name */}
      <p className="product-name">{item.name}</p>
    </div>
  );
};

export default ProductCard;
