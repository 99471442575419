import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";
import Utility from "../../Utils/Utility";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isProfileCompleted: false,
  username: null,
  email: null,
  token: null,
  userInfo: {},
  userid: null,
  showContactUsModal: false,
};

export const login = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.mobileLogin(data);
      console.log(response, "userSlice");
      return response;
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
      return rejectWithValue(error);
    }
  }
);

export const createOrUpdateUser = createAsyncThunk(
  "user/createOrUpdateUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.createdOrUpdatedUser(data);
      Utility.sToast(response?.data?.message);
      return response;
    } catch (error) {
      console.log("error  at user slice", error);
      Utility.eToast(error?.response?.data?.message || error?.message);
      // return rejectWithValue(error);
    }
  }
);
export const userSelfRegister = createAsyncThunk(
  "user/selfRegister",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.userSelfRegister(data);
      Utility.sToast(response?.data?.message);
      return response;
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
      // return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUserInfo: (state, action) => {
      console.log("USER INFO.....", action.payload);
      state.userInfo = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logOut: (state) => {
      state.isLoading = false;
      state.userid = null;
      state.token = null;
      state.userInfo = {};
      state.isError = false;
      state.isSuccess = false;
      state.email = null;
      state.username = null;
      state.showContactUsModal = false;
      localStorage.removeItem("user-token");
    },
    toggleContactUsModal: (state) => {
      state.showContactUsModal = !state.showContactUsModal;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.token = action.payload.data.token;
      state.userInfo = action.payload.data.data;
      state.email = action.payload.data.data.email;
      localStorage.setItem("user-token", action.payload.data.token);
    });
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    });

    builder.addCase(createOrUpdateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createOrUpdateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.token = action?.payload?.data?.token;
      state.userInfo = action?.payload?.data?.data;
      state.email = action?.payload?.data?.data?.email;
      localStorage.setItem("user-token", action?.payload?.data?.token);
    });
    builder.addCase(createOrUpdateUser.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      // state.isError = true;
    });
    builder.addCase(userSelfRegister.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userSelfRegister.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.token = action?.payload?.data?.token;
      state.userInfo = action?.payload?.data?.data;
      state.email = action?.payload?.data?.data?.email;
      localStorage.setItem("user-token", action?.payload?.data?.token);
    });
    builder.addCase(userSelfRegister.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      // state.isError = true;
    });
  },
});

export const {
  logOut,
  setToken,
  setIsLoading,
  setUserInfo,
  toggleContactUsModal,
} = userSlice.actions;
export default userSlice.reducer;
