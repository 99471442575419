import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";
import { logOut } from "./userSlice";

const initialState = {
  categoryLoading: false,
  categories: [],
};

export const fetchAllCategoriesAsync = createAsyncThunk(
  "category/fetchAllCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserController.getAllCategory();
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllCategoriesAsync.pending, (state) => {
      state.categoryLoading = true;
    });
    builder.addCase(fetchAllCategoriesAsync.fulfilled, (state, action) => {
      state.categoryLoading = false;
      state.categories = action.payload?.data;
    });
    builder.addCase(fetchAllCategoriesAsync.rejected, (state) => {
      state.categoryLoading = false;
    });
    builder.addCase(logOut, (state) => {
      state.categoryLoading = false;
      state.categories = [];
    });
  },
});

export const {} = categorySlice.actions;
export default categorySlice.reducer;
