import React from "react";
import "./MainLoader.css";
import { RotatingLines } from "react-loader-spinner";

export default function MainLoader({ marginBottom }) {
  return (
    <div className="main-loader">
      <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="#111"
        strokeWidth="3"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
        strokeColor="#111"
      />
    </div>
  );
}
