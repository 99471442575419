import { HttpClient } from "./HttpClient";

export const loginAPI = (data) => {
  let url = "auth/login";
  return HttpClient.apiCaller(url, "POST", data);
};
export const signUp = (data) => {
  let url = "user/user-self-register";
  return HttpClient.apiCaller(url, "POST", data);
};
export const getProducts = (token) => {
  let url = "product/get-all-products";
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getAllCategory = () => {
  let url = "category/get-all-categories";
  return HttpClient.apiCaller(url, "GET", null);
};
export const AddQuery = (data) => {
  let url = "query/add-new-query";
  return HttpClient.apiCaller(url, "POST", data);
};
export const createdOrUpdatedUser = (data) => {
  let url = "auth/create-or-update-user";
  return HttpClient.apiCaller(url, "POST", data);
};

export const singleDocUpload = (data) => {
  let url = "upload-single-doc";
  return HttpClient.apiCaller(url, "POST", data, "", "multipart/form-data");
};
export const OrderSubmit = (data) => {
  let url = "order/create-order";
  return HttpClient.apiCaller(url, "POST", data);
};
export const RegisterUser = (data) => {
  let url = "auth/register";
  return HttpClient.apiCaller(url, "POST", data);
};
export const getEmployeeDetails = (data) => {
  let url = "user/get-emp-details/" + data?.empID;
  return HttpClient.apiCaller(url, "GET");
};
export const sendOtp = (data) => {
  let url = "auth/send-otp";
  return HttpClient.apiCaller(url, "POST", data);
};
export const mobileLogin = (data) => {
  let url = "auth/mobile-login";
  return HttpClient.apiCaller(url, "POST", data);
};
export const getProductByCategory = (id) => {
  let url = `product/get-by-category/${id}`;
  return HttpClient.apiCaller(url, "GET", null);
};

export const getSpecificUserById = (token, userId) => {
  let url = `order/get-orders-by-user/${userId}`;
  return HttpClient.apiCaller(url, "GET", null, token);
};

export const getProductCategoryById = (userId) => {
  let url = `product/get-by-id/${userId}`;
  return HttpClient.apiCaller(url, "GET");
};
export const verifyEmployee = (data) => {
  let url = `auth/verify-otp`;
  return HttpClient.apiCaller(url, "POST", data);
};
export const downloadYourInvoice = (orderId, token) => {
  let url = `order/download-invoice/${orderId}`;
  return HttpClient.apiCaller(url, "GET", null, token);
};

const createUserOrder = (data) => {
  let url = "order/user-order";
  return HttpClient.apiCaller(url, "POST", data);
};
const verifyPayment = (data) => {
  let url = "order/verify-payment";
  return HttpClient.apiCaller(url, "POST", data);
};
const userSelfRegister = (data) => {
  let url = "auth/self-register";
  return HttpClient.apiCaller(url, "POST", data);
};
// export const GetAllCategory = (token) => {
//   let url = "category/get-all-categories";
//   return HttpClient.apiCaller(url, "GET", null, token);
// };

export const UserController = {
  loginAPI,
  signUp,
  RegisterUser,
  getProducts,
  getAllCategory,
  OrderSubmit,
  AddQuery,
  singleDocUpload,
  createdOrUpdatedUser,
  getEmployeeDetails,
  sendOtp,
  mobileLogin,
  getSpecificUserById,
  getProductCategoryById,
  verifyEmployee,
  getProductByCategory,
  downloadYourInvoice,
  createUserOrder,
  verifyPayment,
  userSelfRegister,
};
