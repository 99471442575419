// Checkout.jsx
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RazorpayCheckout from "./RazorpayCheckout";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedAddress = location?.state?.selectedAddress;

  useEffect(() => {
    if (!selectedAddress) {
      navigate("/", { replace: true });
    }
  }, [selectedAddress, navigate]);

  if (!selectedAddress) {
    return null;
  }

  return <RazorpayCheckout selectedAddress={selectedAddress} />;
};

export default Checkout;
