import { useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController";
import "./ContactusModal.css";
import MainLoader from "./../../../Globalcomponents/Loader/MainLoader";
import Utility from "../../../Utils/Utility";

export default function ContactUsModal(props) {
  const { userInfo } = useSelector((state) => state?.user);

  const [input, setInput] = useState({
    name: userInfo?.name || "",
    email: userInfo?.email || "",
    phoneNumber: userInfo?.phoneNo || "",
    interestedProduct: "",
    message: "",
  });

  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await UserController.AddQuery(input);
      Utility.sToast("Query submitted successfully! We will contact you soon.");
      props.onHide();
    } catch (error) {
      Utility.eToast("Something went wrong. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contact-us-modal-title"
      centered
      id="contact-us-modal"
    >
      <Modal.Header closeButton style={{ background: "#F4F4F2" }}>
        <Modal.Title id="contact-us-modal-title" className="contact-us-heading">
          Please provide your contact information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="contact-us-body">
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="w-100"
        >
          <Form.Group className="mb-3">
            <Form.Control
              disabled={!!userInfo?.name}
              className="contact-us-input"
              placeholder="Enter your Name"
              name="name"
              onChange={handleChange}
              value={input.name}
              required
            />
            <Form.Control.Feedback type="invalid">
              Enter your name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              disabled={!!userInfo?.email}
              className="contact-us-input"
              placeholder="Enter your Email address"
              name="email"
              onChange={handleChange}
              value={input.email}
              type="email"
              required
            />
            <Form.Control.Feedback type="invalid">
              Enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              disabled={!!userInfo?.phoneNo}
              type="number"
              className="contact-us-input"
              placeholder="Enter your Phone Number"
              name="phoneNumber"
              onChange={handleChange}
              value={input.phoneNumber}
              required
              pattern="\d{10}"
            />
            <Form.Control.Feedback type="invalid">
              Enter a valid 10-digit mobile number.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              className="contact-us-input"
              placeholder="Enter Interested Product"
              name="interestedProduct"
              onChange={handleChange}
              value={input.interestedProduct}
              required
            />
            <Form.Control.Feedback type="invalid">
              Enter the product you're interested in.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              className="contact-us-input"
              as="textarea"
              rows={4}
              placeholder="Enter your message"
              name="message"
              onChange={handleChange}
              value={input.message}
              required
              minLength={3}
            />
            <Form.Control.Feedback type="invalid">
              Message should be at least 3 characters long.
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-grid">
            <Button
              id="contact-us-button"
              size="lg"
              variant="secondary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit Query
            </Button>
          </div>
        </Form>
        {isSubmitting && <MainLoader />}
      </Modal.Body>
    </Modal>
  );
}
