import React, { useEffect, useState } from "react";
import "./AllCategories.css";
import { UserController } from "../../Redux/controllers/UserController";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLoader from "../../Globalcomponents/Loader/MainLoader";

const AllCategories = ({}) => {
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const { categories, categoryLoading } = useSelector(
    (state) => state.category
  );

  const handleCategoryClick = (item) => {
    navigate(`/category/${item?._id}`);
  };
  if (categoryLoading) {
    return <MainLoader />;
  }

  return (
    <div className="category-row">
      {categories?.map((item, index) => (
        <div
          className={`category-card ${
            categoryId === item?._id ? "selected" : ""
          }`}
          onClick={() => handleCategoryClick(item)}
        >
          <div className="category-image-container">
            <img src={item.image} alt={item.name} className="category-image" />
          </div>
          <p className="category-name">{item.name}</p>
        </div>
      ))}
    </div>
  );
};

export default AllCategories;
