import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FaHome, FaBoxOpen } from "react-icons/fa"; // Import icons
import "./BreadCrumbComp.css";

export default function BreadCrumbComp({ productName }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Breadcrumb className="dream-breadcrumb">
      <Breadcrumb.Item
        active={location.pathname === "/"}
        onClick={() => {
          navigate("/");
        }}
        id="breadcrumb-item"
      >
        <FaHome className="breadcrumb-icon" /> Home
      </Breadcrumb.Item>

      <Breadcrumb.Item
        active={location.pathname === "/product"}
        onClick={() => {
          navigate("/category");
        }}
        id="breadcrumb-item"
      >
        <FaBoxOpen className="breadcrumb-icon" /> Products
      </Breadcrumb.Item>

      {productName && (
        <Breadcrumb.Item active id="breadcrumb-item breadcrumb-active">
          {productName}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
}
