// OrderCard.jsx
import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaClock, FaCheckCircle, FaTruck, FaBox } from "react-icons/fa";
import "./OrderCard.css";
import Utility from "../../Utils/Utility";
import moment from "moment/moment";

const statusIcons = {
  pending: <FaClock className="dash-order-status-icon" />,
  delivered: <FaCheckCircle className="dash-order-status-icon" />,
  shipping: <FaTruck className="dash-order-status-icon" />,
  processing: <FaBox className="dash-order-status-icon" />,
};

const OrderCard = ({ item, handleShowModal }) => {
  return (
    <div className="dash-order-card" onClick={() => handleShowModal(item)}>
      <Row className="align-items-center">
        <Col sm={2} xs={3}>
          <div className="dash-order-image-container">
            <img
              src={item?.productDetails[0]?.product?.images[0]}
              className="dash-order-image"
              alt={item?.productDetails[0]?.product?.name || "Product"}
            />
          </div>
        </Col>
        <Col sm={7} xs={9}>
          <div className="dash-order-content">
            <h6 className="dash-order-title">
              {item?.productDetails[0]?.product?.name}
            </h6>
            <div className="dash-order-details">
              <span className="dash-order-id">{item.orderID}</span>
              <span className="dash-order-price">
                {Utility.MoneyFormatter(item.paymentDetails.totalAmount)}
              </span>
            </div>
            <p className="dash-order-address">
              {item.customerDetails.shippingAddress}
            </p>
          </div>
        </Col>
        <Col sm={3} className="text-end">
          <div className="dash-order-side-content">
            <div className={`dash-order-status-badge ${item.deliveryStatus}`}>
              {statusIcons[item.deliveryStatus]}
              <span>{item.deliveryStatus}</span>
            </div>
            <span className="dash-order-date">
              {moment(item.createdAt).format("MMM DD, YYYY")}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderCard;
