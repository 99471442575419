import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Landing.css";
import { Outlet } from "react-router-dom";
import AllCategories from "./AllCategories";

const CategoryLayout = () => {
  return (
    <Container id="landingDiv">
      <AllCategories />

      <div className="landing-line" />
      <Outlet />
    </Container>
  );
};

export default CategoryLayout;
