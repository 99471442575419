import jbsLogo from "../Assets/jbs/jbsLogo.svg";
import jbsWithNameLogo from "../Assets/jbs/jbsWithNameLogo.svg";
import jbsWhiteLogo from "../Assets/jbs/jbsWhiteLogo.svg";
import jbsBrandLogo from "../Assets/jbs/jbsBrandLogo.svg";
import bedImage from "../Assets/DreamComfortimg/bed.png";
import photo1 from "../Assets/branding/photo1.jpg";
import photo2 from "../Assets/branding/photo2.jpg";
import photo3 from "../Assets/branding/photo3.jpg";
import photo4 from "../Assets/branding/photo4.jpg";
import photo5 from "../Assets/branding/photo5.jpg";
import sofa from "../Assets/UserHome/sofa.png";
import kitchen from "../Assets/UserHome/kitchen.png";
import interior from "../Assets/UserHome/interior.png";
import bed from "../Assets/UserHome/bed.jpg";
import flower from "../Assets/UserHome/flower.png";
import woodenChair from "../Assets/UserHome/woodenChair.jpg";
import furniture from "../Assets/UserHome/furniture.png";
import fabrics from "../Assets/UserHome/fabrics.png";
import f1 from "../Assets/UserHome/Frame 10.jpg";
import f2 from "../Assets/UserHome/Frame 11.jpg";
import f3 from "../Assets/UserHome/Frame 12.jpg";
import f4 from "../Assets/UserHome/Rectangle 4.png";
import f5 from "../Assets/UserHome/Rectangle 5.png";
import f6 from "../Assets/UserHome/Rectangle 6.png";

const IMAGES = {
  jbsLogo,
  jbsWithNameLogo,
  jbsWhiteLogo,
  jbsBrandLogo,
  bedImage,
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  userHome: {
    sofa,
    kitchen,
    interior,
    bed,
    flower,
    woodenChair,
    furniture,
    fabrics,
    f1,
    f2,
    f3,
    f4,
    f5,
    f6,
  },
};

export default IMAGES;
