import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./StartCustomizeContent.css";
import IMAGES from "../../constants/image";

const StartCustomizeContent = ({}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 576);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Row className="scc-second-row mb-5">
      <div className="scc-title-container">
        <h2 className="scc-heading">Start - Customizing Now</h2>
      </div>

      <Col sm={3} className="scc-image-stack">
        <div className={`scc-stack-item ${isMobile ? "mb-3" : ""}`}>
          <img
            className="scc-feature-image"
            alt="Custom furniture"
            src={IMAGES.userHome.f5}
          />
        </div>
        <div className={`scc-stack-item ${isMobile ? "mb-3" : ""}`}>
          <img
            className="scc-feature-image"
            alt="Custom design"
            src={IMAGES.userHome.f6}
          />
        </div>
      </Col>

      <Col sm={3} className={`${isMobile ? "mb-4" : ""}`}>
        <div className="scc-single-image">
          <img
            className="scc-feature-image"
            src={IMAGES.userHome.f4}
            alt="Featured design"
          />
        </div>
      </Col>

      <Col sm={6} className={`${isMobile ? "mb-3" : ""}`}>
        <Container className="scc-content-container">
          <div className="scc-content">
            <div className="scc-main-heading">
              <h1>Custom Furniture Design</h1>
            </div>
            <div className="scc-subheading">
              <h5>Furniture in your own style and size</h5>
            </div>
            <div className="scc-description">
              <p>
                We are a furniture manufacturer with large capacity to design
                and manufacture wooden furniture on order.
              </p>
            </div>
            <div className="scc-contact-text">
              <p>Let's talk over Phone or WhatsApp</p>
            </div>
            <div className="scc-button-container">
              <button className="scc-contact-button ">+91 8050141583</button>
            </div>
          </div>
        </Container>
      </Col>
    </Row>
  );
};

export default StartCustomizeContent;
