import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserController } from "../../Redux/controllers/UserController";
import { useDispatch } from "react-redux";
import { fetchCart, fetchCartAsync } from "../../Redux/Slice/cartSlice";
import Utility from "../../Utils/Utility";

const useRazorpayCheckout = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      if (window.Razorpay) return resolve(true);

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const initiatePayment = async (selectedAddress) => {
    try {
      setIsProcessing(true);

      // Load Razorpay SDK
      const isLoaded = await loadRazorpayScript();
      if (!isLoaded) throw new Error("Razorpay SDK failed to load");

      // Create Order
      const orderResponse = await UserController.createUserOrder({
        shippingAddress:
          selectedAddress?.name +
          ", " +
          selectedAddress?.street +
          ", " +
          selectedAddress?.city +
          ", " +
          selectedAddress?.state +
          ", " +
          selectedAddress?.postalCode +
          ", " +
          selectedAddress?.country,
        paymentMethod: "razorpay",
      });

      if (
        !orderResponse?.data?.success ||
        !orderResponse?.data?.data?.razorpayOrderId
      ) {
        throw new Error("Order creation failed");
      }

      const { razorpayOrderId, remainingAmount } = orderResponse?.data?.data;

      // Initialize Razorpay Options
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: Math.round(remainingAmount * 100),
        currency: "INR",
        name: "JBS Interior",
        description: "Order Payment",
        order_id: razorpayOrderId,
        handler: async function (response) {
          try {
            await UserController.verifyPayment({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });
            dispatch(fetchCartAsync());
            navigate("/dashboard", { replace: true });
          } catch (error) {
            Utility.eToast(error?.response?.data?.message || "Payment failed");
          }
        },
        prefill: {
          name: selectedAddress?.name || "",
          contact: selectedAddress?.phone || "",
          email: selectedAddress?.email || "",
        },
        modal: {
          confirm_close: true,
          ondismiss: function (reason) {
            setIsProcessing(false);
          },
        },
        retry: { enabled: false },
        timeout: 300,
        theme: { color: "#111" },
      };

      // Create Razorpay Instance and Open Modal
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return { initiatePayment, isProcessing };
};

export default useRazorpayCheckout;
