import { HttpClient } from "./HttpClient";

const addToCart = (data) => {
  let url = `cart`;
  return HttpClient.apiCaller(url, "POST", data);
};
const updateCart = (data) => {
  let url = `cart/update`;
  return HttpClient.apiCaller(url, "PATCH", data);
};

const fetchCart = () => {
  let url = `cart`;
  return HttpClient.apiCaller(url, "GET", null);
};

const removeCartItem = (data) => {
  let url = `cart/item`;
  return HttpClient.apiCaller(url, "DELETE", data);
};

const clearCart = (data) => {
  let url = `cart/clear`;
  return HttpClient.apiCaller(url, "DELETE", data);
};

const addAddress = (data) => {
  let url = `address`;
  return HttpClient.apiCaller(url, "POST", data);
};
const fetchAddresses = () => {
  let url = `address`;
  return HttpClient.apiCaller(url, "GET", null);
};

const removeAddress = (id) => {
  let url = `address/${id}`;
  return HttpClient.apiCaller(url, "DELETE", null);
};

const updateAddress = (id, data) => {
  let url = `address/${id}`;
  return HttpClient.apiCaller(url, "PUT", data);
};

export const CartController = {
  fetchCart,
  removeCartItem,
  clearCart,
  addToCart,
  updateCart,
  addAddress,
  fetchAddresses,
  removeAddress,
  updateAddress,
};
