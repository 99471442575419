import { RotatingLines } from "react-loader-spinner";

function Loader(props) {
  return (
    <RotatingLines
      visible={true}
      height="64"
      width="64"
      color="#111"
      strokeWidth="3"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      wrapperStyle={{}}
      wrapperClass=""
      strokeColor="#111"
    />
  );
}

export default Loader;
