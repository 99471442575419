import React, { useEffect, useState } from "react";
import "./UserHome.css";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShopByCategoryCard from "./ShopByCategoryCard";
import CustomizeFurnitureCard from "./CustomizeFurnitureCard";
import StartCustomizeContent from "./StartCustomizeContent";
import MainContent from "./MainContent";

export default function UserHome({}) {
  const navigate = useNavigate();
  const handleShopNow = () => {
    navigate("/category");
    window.scrollTo(0, 0);
  };

  return (
    <Container
      style={{
        marginTop: "5rem",
      }}
    >
      {/* FIRST ROW  */}

      <MainContent handleShopNow={handleShopNow} />

      {/* second row  */}

      <ShopByCategoryCard />

      {/* third row */}

      <CustomizeFurnitureCard handleShopNow={handleShopNow} />

      {/* fourth row  */}

      <StartCustomizeContent />
    </Container>
  );
}
