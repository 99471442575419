import { useEffect } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartAsync } from "./../Redux/Slice/cartSlice";
import MainNavbar from "../Globalcomponents/Navbar/MainNavbar";
import Footer from "../Globalcomponents/footer/MainFooter";
import UserHome from "../pages/UserHome/UserHome";
import LuxuryHarmony from "../pages/Products/LuxuryHarmony";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import SignUp from "../pages/SignUp/SignUp";
import Cart from "../pages/Cart/Cart";
import Checkout from "../pages/Checkout/Checkout";
import CategoryLanding from "../pages/Products/CategoryLanding";
import CategoryLayout from "../pages/Products/CategoryLayout";
import CategoryProducts from "../pages/Products/CategoryProducts";
import ProductDetails from "../pages/ProductDetails/ProductDetails";
import { fetchAllCategoriesAsync } from "../Redux/Slice/categorySlice";

const MainLayout = () => (
  <div className="d-flex flex-column min-vh-100">
    <MainNavbar />
    <main className="flex-grow-1" style={{ paddingTop: "60px" }}>
      <Outlet />
    </main>
    <Footer />
  </div>
);

// Protected route wrapper
const ProtectedRoute = ({ children }) => {
  const { token } = useSelector((state) => state.user);
  return token ? children : <Navigate to="/login" />;
};

export default function RootRouter() {
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCategoriesAsync());
    if (token) {
      dispatch(fetchCartAsync());
    }
  }, [token]);

  return (
    <>
      <Routes>
        {/* Auth Routes - Without Header/Footer */}
        <Route
          path="/login"
          element={token ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/signup"
          element={token ? <Navigate to="/" /> : <SignUp />}
        />

        {/* All other routes - With Header/Footer */}
        <Route element={<MainLayout />}>
          {/* Public Routes */}
          <Route path="/" element={<UserHome />} />
          <Route path="/luxury-harmony" element={<LuxuryHarmony />} />
          <Route path="/category" element={<CategoryLayout />}>
            <Route index element={<CategoryLanding />} />
            <Route path=":categoryId" element={<CategoryProducts />} />
          </Route>
          <Route
            path="/product-details/:productId"
            element={<ProductDetails />}
          />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ProtectedRoute>
                <Cart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
        </Route>

        {/* Catch all route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}
